import { get, httpDelete, patch, post } from './axios';

/* auth requests */
export const refreshToken = (refreshToken) => post('/auth/refresh', { refreshToken });
export const loginWithEmail = (body) => post('/auth/email/login', body);
export const getGoogleAuthUrl = (body) => post('/auth/getGoogleAuthUrl', body);
export const getGoogleConnectUrl = (body) => post('/auth/getGoogleConnectUrl', body);
export const getGoogleCalendarConnectUrl = (body) => post('/auth/getGoogleCalendarConnectUrl', body);
export const signupWithEmail = (body) => post('/auth/email/signup', body);
export const checkEmail = (body) => post('/auth/email/checkEmail', body);
export const checkUserName = (body) => post('/auth/email/checkUserName', body);
export const sendInviteToRegistration = (body) => post('/auth/sendInviteToRegistration', body);
export const checkRegistrationToken = (body) => post('/auth/checkRegistrationToken', body);

/* user requests */
export const sendVerificationCode = () => get('/users/sendCodeToConfirmEmail');
export const verifyEmail = (body) => post('/users/verifyEmail', body);
export const editAccountInformation = (body) => patch('/users', body);
export const getUser = () => get('/users/currentUser');
export const getUserByUserName = (body) => post('/users/getUserByUserName', body);
export const forgotPassword = (body) => post('/users/forgotPassword', body);
export const checkForgotPasswordToken = (body) => post('/users/checkForgotPasswordToken', body);
export const changeUserPassword = (body) => post('/users/changePassword', body);
export const changeUserPasswordByOldPass = (body) => post('/users/changePasswordByOldPass', body);
export const findUserByEmail = (body) => post('/users/findUserByEmail', body);
export const sendDeleteLink = (body) => post('/users/sendDeleteLink', body);
export const getZoomCredentials = (body) => post('/users/getZoomCredentials', body);
export const disconnectZoom = () => get('/users/disconnectZoom');
export const disconnectGoogle = (body) => post('/users/disconnectGoogle', body);
export const disconnectGoogleCalendar = (body) => post('/users/disconnectGoogleCalendar', body);
export const deleteTeamMember = (body) => post('/users/deleteTeamMember', body);
export const changeUserRole = (body) => post('/users/changeUserRole', body);
export const getGoogleEvents = () => get('/users/getGoogleEvents');
export const saveGoogleEvents = () => get('/users/saveGoogleEvents');
export const getGoogleEventsByUserName = (body) => post('/users/getGoogleEventsByUserName', body);
export const updateUserTimeZone = (body) => patch('users/updateUserTimeZone', body);
export const getUserByUserId = (body) => post('/users/getUserByUserId', body);

/* meetings requests */
export const saveMeeting = (body) => post('/meetings', body);
export const loadMeetings = () => get(`/meetings/getAllAttachedMeetings`);
export const loadAllAttachedMeetingsByUserId = (body) => post('/meetings/getMeetingsByUserId', body);
export const updateMeeting = (data) => patch(`/meetings/${data.id}/editAttachedMeeting`, data.body);
export const changeAboutMeAndTagline = (body) => patch('/meetings/changeAboutMeAndTagline', body);
export const loadMeetingById = (id) => get(`/meetings/${id}/getAttachedMeeting`);
export const loadMeetingByMeetingRoute = (body) => post(`/meetings/meetingByRoute`, body);
export const deleteMeetingById = (id) => httpDelete(`/meetings/${id}/deleteAttachedMeeting`);
export const toggleAssignAll = (body) => post('/meetings/toggleAssignAll', body);
export const updateMeetingTeamMemberData = (body) => patch('/meetings/updateMeetingTeamMemberData', body);

/* orders requests */
export const getOrdersByUserId = () => get('/orders');
export const getAllNotifications = () => get('/notifications');
export const getOrdersByMeetingRoute = (body) => post('/orders/getOrdersByMeetingRoute', body);
export const getOrdersByUsername = (body) => post('/orders/getOrdersByUserName', body);
export const addCustomerToOrder = (body) => patch('/orders/addNewCustomer', body);
export const findOneOrder = (body) => post('/orders/findOneOrder', body);
export const updateOrderFieldsById = (body) => patch('/orders/editOrderData', body);
export const deleteOrderById = (body) => httpDelete('/orders/deleteOrder', body);
export const removeCustomerById = (body) => patch('/orders/removeCustomerById', body);
export const checkAvailabilityOfTime = (body) => post('/orders/checkAvailabilityOfTime', body);
export const paymentToMeeting = (body) => post('/orders/paymentToMeeting', body);
export const getNonBookedTimesByUsersId = (body) => post('/orders/getNonBookedTimesByUsersId', body);

/**
 *@deprecated, the entire logic of creating a new Order has been transferred to 'paymentToMeeting'
 */
export const saveOrder = (body) => post('/orders', body);

/* notifications requests */
export const updateViewedNotifications = (body) => patch('/notifications/updateNotifications', body);
export const sendOverlappingOrdersNotifications = (body) =>
  post('/notifications/sendOverlappingOrdersNotifications', body);

/*youtube api requests*/
export const getVideoInfo = (videoId, API_KEY) =>
  fetch(`https://www.googleapis.com/youtube/v3/videos?key=${API_KEY}&part=snippet&id=${videoId}`, { method: 'GET' });

/* OpenStreetMap api requests*/
export const getCoordinates = (query) =>
  fetch(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${query}&format=json&limit=1`, {
    method: 'GET',
  });
