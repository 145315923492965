export const meetingType = [
  {
    name: 'oneOnOne', // The constant must match the constant 'TYPE_ID' on the backend at the address 'src/interfaces/meeting.interfaces.ts'
    title: '1-On-1 Meeting', // The constant must match the constant 'TYPE_NAMES_ID' on the backend at 'src/interfaces/meeting.interfaces.ts'
    description: 'A private appointment that happens once.',
    isGroupMeeting: false,
  },
  {
    name: 'group', // The constant must match the constant 'TYPE_ID' on the backend at the address 'src/interfaces/meeting.interfaces.ts'
    title: 'Group Meeting', // The constant must match the constant 'TYPE_NAMES_ID' on the backend at the address 'src/interfaces/meeting.interfaces.ts'
    description: 'For 3+ participants and happens once.',
    isGroupMeeting: true,
  },

  /*this meeting types will add to version higher than 1.0v*/
  // {
  //   id: 'oneByOneRegularly',
  //   name: 'Recurring 1-On-1 Meetings',
  //   description: 'A private meeting that happens regularly (every week, every month etc.)',
  //   type: OneOnOneType,
  //   isOnceMeeting: false,
  // },
  // {
  //   id: 'groupOnceRegularly',
  //   name: 'Recurring Group Meetings',
  //   description: 'For 3+ participants and happens regularly (every week, every month etc.)',
  //   type: groupType,
  //   isOnceMeeting: false,
  // }
];

export const landingOptionId = 'LandingPage';
export const bookingOptionId = 'BookingPage';

export const meetingOptions = [
  {
    id: landingOptionId,
    name: 'A Landing Page',
    description: 'Create a landing page for your appointment and offer your customers to book a time with you.',
  },
  {
    id: bookingOptionId,
    name: 'Booking Only',
    description: 'Let your customers book an appointment from your website or through a custom link.',
  },
];

export const zoomId = 'zoom';
export const googleMeetId = 'google-meet';
export const inPersonMeetingId = 'in-person';
export const customSolutionId = 'custom';

export const noLinkAvailable = 'no-link-available';
export const noLinkUrl = 'https://dev.weshare.net'; //default link to prevent location URL error

/* { name: 'Zoom', id: zoomId, isHideOnAddLocationPage: false }, */

export const meetingLocation = [
  { name: 'Google Meet', id: googleMeetId, isHideOnAddLocationPage: false },
  { name: 'In-person Meeting', id: inPersonMeetingId, isHideOnAddLocationPage: false },
  { name: 'Custom Solution', id: customSolutionId, isHideOnAddLocationPage: false },
  { name: 'No link available', id: noLinkAvailable, isHideOnAddLocationPage: true },
];

export const timeArr = [
  10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 1, 75, 1.5, 105, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
  9.5,
];
export const breakTimeArr = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export const amountArr = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];
export const amountRevArr = [
  30, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
];

export const defaultDuration = {
  sessionTime: 45,
  breakTime: 15,
};

export const participantsArr = new Array(99).fill('').map((_, index) => index + 2);

export const priceArr = new Array(202).fill('').map((_, index) => {
  if (index === 0) return 1;
  if (index === 1) return 3;
  return (index - 1) * 5;
});

export const initialBenefits = [{ name: '24/7 Availability', isBasicChecked: false, isSubscriptionChecked: false }];

export const initialThankYouPageValue = {
  title: 'Thank You!',
  text: `<p>Your appointment booking was successful.</p>
  <p>We're looking forward to meeting you :)</p>
  <p>Appointment Details:</p>
  <ul>
  <li>Title: {meetingTitle}</li>
  <li>Date: {meetingDate}</li>
  <li>Time: {meetingTime}</li>
  <li>With: {vendorName}</li>
  <li>Location: {meetingLocation}</li>
  </ul>
  <p>The best way to get reminders to your appointment is by adding it to your calendar:</p>`,
};

export const initialBasicPackage = {
  idName: 'basic',
  name: 'Book an appointment👇',
  description: '',
  sessionTime: defaultDuration.sessionTime,
  breakTime: defaultDuration.breakTime,
  meetingsPerDay: 30,
  price: 0,
};

export const initialSubscriptionPackage = {
  idName: 'subscription',
  name: 'Subscription',
  description: '',
  price: 15,
};
