import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api';
import { asyncActionsCreator } from 'store/reducersHelper';

export const updateUserTimeZone = createAsyncThunk(
  'user/updateUserTimezone',
  async (timeZone) => await api.updateUserTimeZone({ timeZone }),
);

export const editAccountInformation = createAsyncThunk(
  'user/editAccountInformation',
  async (editInfo) => await api.editAccountInformation(editInfo),
);

export const checkUserName = createAsyncThunk(
  'user/checkUserName',
  async (username) => await api.checkUserName(username),
);

export const checkEmail = createAsyncThunk('user/checkEmail', async (email) => await api.checkEmail(email)); // rewrite signup up after move to toolkit

export const checkForgotPasswordToken = createAsyncThunk(
  'user/checkForgotPasswordToken',
  async (token) => await api.checkForgotPasswordToken(token),
);

export const refreshToken = createAsyncThunk('user/refreshToken', async (token) => await api.refreshToken(token));

export const getUser = createAsyncThunk('user/getUser', async () => await api.getUser());

export const getUserByUserName = createAsyncThunk(
  'user/getUserByUserName',
  async (userName) => await api.getUserByUserName(userName),
);

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (email) => await api.forgotPassword(email));

export const changeUserPassword = createAsyncThunk(
  'user/changeUserPassword',
  async (email) => await api.changeUserPassword(email),
);

export const changeUserPasswordByOldPass = createAsyncThunk(
  'user/changeUserPasswordByOld',
  async (passwords) => await api.changeUserPasswordByOldPass(passwords),
);

export const loginWithEmail = createAsyncThunk(
  'user/loginWithEmail',
  async (userCredentials) => await api.loginWithEmail(userCredentials),
);

export const signupWithEmail = createAsyncThunk(
  'user/signupWithEmail',
  async (signUpData) => await api.signupWithEmail(signUpData),
);

export const findUserByEmail = createAsyncThunk(
  'user/findUserByEmail',
  async (email) => await api.findUserByEmail(email),
);

export const sendDeleteLink = createAsyncThunk('user/sendDeleteLink', async (email) => await api.sendDeleteLink(email));

export const getZoomCredentials = createAsyncThunk(
  'user/getZoomCredentials',
  async (code) => await api.getZoomCredentials(code),
);

export const disconnectZoom = createAsyncThunk('user/disconnectZoom', async () => await api.disconnectZoom());

export const disconnectGoogle = createAsyncThunk(
  'user/disconnectGoogle',
  async (data) => await api.disconnectGoogle(data),
);

export const disconnectGoogleCalendar = createAsyncThunk(
  'user/disconnectGoogleCalendar',
  async (data) => await api.disconnectGoogleCalendar(data),
);

export const deleteTeamMember = createAsyncThunk(
  'user/deleteTeamMember',
  async (data) => await api.deleteTeamMember(data),
);

export const changeUserRole = createAsyncThunk('user/changeUserRole', async (data) => await api.changeUserRole(data));

export const getGoogleEvents = createAsyncThunk('user/getGoogleEvents', async () => await api.getGoogleEvents());

export const saveGoogleEvents = createAsyncThunk('user/saveGoogleEvents', async () => await api.saveGoogleEvents());

export const getGoogleEventsByUserName = createAsyncThunk(
  'user/getGoogleEventsByUserName',
  async (data) => await api.getGoogleEventsByUserName(data),
);

const initialState = {
  isEditAccountInfoLoading: false,
  loginWithEmailError: null,
  forgotPasswordError: null,
  googleConnectError: null,
  user: null,
  refreshToken: null,
  accessToken: null,
  isGoogleCalendarConnected: null,
  isUserCreateSuccessfulWithEmail: null,
  isUserCreateSuccessfulWithGoogle: null,
  isUserNameValid: true,
  isUserLoginSuccessful: null,
  isEmailValid: null,
  isPasswordTokenValid: null,
  isPasswordChange: null,
  isSuccessEditAccountInfo: null,
  googleCalendarEvents: [],
  calendarDate: new Date().toString(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    resetUser: () => initialState,

    resetUserError: (state, { payload }) => {
      state[payload] = null;
    },
    setUserError: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    setIsNameValid: (state, { payload }) => {
      state.isUserNameValid = payload;
    },
    setCalendarDay: (state, { payload }) => {
      state.calendarDate = payload;
    },
  },
  extraReducers: {
    ...asyncActionsCreator(
      editAccountInformation,
      'editAccountInfo',
      {
        fulfilled: (state, { payload }) => {
          state.isEditAccountInfoLoading = false;
          state.isSuccessEditAccountInfo = true;
          state.user = payload.user;
        },
        rejected: (state, { error }) => {
          state.isEditAccountInfoLoading = false;
          state.isSuccessEditAccountInfo = false;
          state.editAccountInformationError = error;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      checkUserName,
      'checkUserName',
      {
        fulfilled: (state, { payload }) => {
          state.isCheckUserNameLoading = false;
          state.isUserNameValid = payload.isUserNameValid;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      checkForgotPasswordToken,
      'checkForgotPasswordToken',
      {
        fulfilled: (state) => {
          state.isCheckForgotPasswordTokenLoading = false;
          state.isPasswordTokenValid = true;
        },
        rejected: (state, { error }) => {
          state.isCheckForgotPasswordTokenLoading = false;
          state.checkForgotPasswordTokenError = error;
          state.isPasswordTokenValid = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(checkEmail, 'checkEmail', {
      fulfilled: (state) => {
        state.isEmailValid = true;
      },
    }),

    ...asyncActionsCreator(changeUserPassword, 'changeUserPassword', {
      fulfilled: (state) => {
        state.isPasswordChange = true;
      },
    }),

    ...asyncActionsCreator(
      changeUserPasswordByOldPass,
      'changeUserPassword',
      {
        fulfilled: (state) => {
          state.isChangeUserPasswordLoading = false;
          state.isPasswordChange = true;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      getUser,
      'getUser',
      {
        fulfilled: (state, { payload }) => {
          state.isGetUserLoading = false;
          state.user = payload.user;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(forgotPassword, 'forgotPassword'),

    // not used action yet (не впевнений, що рефреш потрібно робити в саме в редаксі)
    ...asyncActionsCreator(refreshToken, 'refreshToken'),

    ...asyncActionsCreator(
      loginWithEmail,
      'loginWithEmail',
      {
        fulfilled: (state, { payload }) => {
          state.isLoginWithEmailLoading = false;
          state.user = payload.user;
          state.refreshToken = payload.refreshToken;
          state.accessToken = payload.accessToken;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(signupWithEmail, 'signupWithEmail', {
      fulfilled: (state, { payload }) => {
        state.refreshToken = payload.refreshToken;
        state.accessToken = payload.accessToken;
        state.user = payload.user;
        state.isUserCreateSuccessfulWithEmail = true;
      },
    }),

    ...asyncActionsCreator(sendDeleteLink, 'sendDeleteLink', {
      rejected: (state, { error }) => {
        state.sendDeleteLink = error;
      },
    }),

    ...asyncActionsCreator(getZoomCredentials, 'getZoomCredentials', {
      fulfilled: (state, { payload }) => {
        state.user = payload;
      },
    }),

    ...asyncActionsCreator(disconnectGoogleCalendar, 'disconnectGoogleCalendar', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
        state.user = payload.updateUser ? payload.updateUser : payload.updatedUser;
      },
    }),

    ...asyncActionsCreator(disconnectGoogle, 'disconnectGoogle', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
        state.user = payload.updateUser ? payload.updateUser : payload.updatedUser;
      },
    }),

    ...asyncActionsCreator(getGoogleEvents, 'getGoogleEvents', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload;
      },
    }),

    ...asyncActionsCreator(saveGoogleEvents, 'saveGoogleEvents', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
      },
    }),

    ...asyncActionsCreator(getGoogleEventsByUserName, 'getGoogleEventsByUserName', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
      },
    }),
  },
});

export const { setUser, resetUser, resetUserError, setUserError, setIsNameValid, setCalendarDay } = userSlice.actions;

export default userSlice.reducer;
