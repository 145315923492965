import { createSelector } from 'reselect';

const getAuthDataObject = (state) => state.authData;
const getCurrentMeetingDataObject = (state) => state.currentMeetingData;
const getMeetingsDataObject = (state) => state.meetingsData;
const getNotDeletedMeetingDataObject = (state) => state.meetingsData.meetings;
const getUserDataObject = (state) => state.userData;
const getOrdersDataObject = (state) => state.ordersData;

export const authDataSelector = createSelector([getAuthDataObject], (authData) => authData);
export const currentMeetingDataSelector = createSelector(
  [getCurrentMeetingDataObject],
  (currentMeetingData) => currentMeetingData,
);
export const meetingsDataSelector = createSelector([getMeetingsDataObject], (meetingsData) => ({
  ...meetingsData,
  meetings: meetingsData.meetings?.map((meeting, index) => ({
    ...meeting,
    meetingClass: `meeting-type-${index}`,
  })),
}));
//TODO: analyze where it is best to add a property 'meeting-type-...'
export const notDeletedMeetingsDataSelector = createSelector([getNotDeletedMeetingDataObject], (meetings) =>
  meetings?.filter(
    (meeting, index) =>
      !meeting.isDeleted && {
        ...meeting,
        meetingClass: `meeting-type-${index}`,
      },
  ),
);
export const userDataSelector = createSelector([getUserDataObject], (userData) => userData);
export const ordersDataSelector = createSelector([getOrdersDataObject], (ordersData) => ordersData);
