import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Loader from './sharedComponents/Loader';

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = useAuth();

  if (isLoggedIn === null) {
    return <Loader show={true} />;
  }

  return isLoggedIn ? <Component {...rest} /> : <Navigate to={'/signup'} />;
}

export default PrivateRoute;
